<template>
  <v-card>
    <v-btn
      v-if="userIsSalesCloud"
      icon
      :to="`${$router.currentRoute.path}/${channel.uuid}`"
      style="right: 0; position: absolute;"
    >
      <v-icon>
        mdi-magnify
      </v-icon>
    </v-btn>
    <v-card-title>
      {{ channel.label }}
    </v-card-title>
    <v-card-subtitle>{{ channel.description }}</v-card-subtitle>
    <v-card-text>
      <v-container
        fluid
        class="pa-0"
      >
        <v-row
          align="center"
          dense
        >
          <v-col cols="auto">
            <v-chip outlined>
              <span
                class="pr-2"
                style="font-weight: 600;"
              >
                {{$t('UUID')}}
              </span>
              <v-divider vertical />
              <span class="pl-2">
                {{ channel.uuid }}
              </span>
            </v-chip>
          </v-col>
          <v-col cols="auto">
            <v-btn
              icon
              small
              @click="copyChannelUUID"
            >
              <v-icon>
                mdi-content-copy
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="flex"
        v-if="isWebpos"
        target="_blank"
        color="success"
        :href="'https://epos.salescloud.is?organization=' + organizationUUID + '&channel=' + channel.uuid"
      >
        {{$t('view')}}
      </v-btn>
      <v-btn
        v-if="showNewForm"
        class="flex"
        color="primary"
        :to="'/company/channels/' + channel.uuid"
      >
        {{$t('manage')}}
      </v-btn>
      <v-btn
        v-if="!showNewForm"
        class="flex"
        color="primary"
        target="_blank"
        :href="editURL"
      >
        {{$t('manage')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'ChannelCard',
  props: {
    channel: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    showNewForm() {
      return false
    },
    userIsSalesCloud() {
      return this.$store.getters.userIsAdmin
    },
    organizationUUID() {
      return this.$store.state.organization.uuid
    },
    isWebpos() {
      return this.channel.label.toLowerCase().includes('webpos')
    },
    editURL() {
      return 'https://admin.salescloud.is/company/channels/' + this.channel.uuid + '/edit'
    }
  },
  methods: {
    async copyChannelUUID() {
      await navigator.clipboard.writeText(this.channel.uuid)
    }
  }
}
</script>
